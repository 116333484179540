/* eslint-disable no-underscore-dangle */
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import userState from '../context/user';

const Member = lazy(() => import(/* webpackChunkName: "Member" */ './Member'));
const Signin = lazy(() => import(/* webpackChunkName: "Signin" */ './Signin'));
const Reset = lazy(() => import(/* webpackChunkName: "Reset" */ './Reset'));
const Register = lazy(() =>
    import(/* webpackChunkName: "Register" */ './Register')
);

function Account({ accountToggle }) {
    const { type } = userState.snapshot;

    return (
        <>
            {type === 'signin' && <Signin accountToggle={accountToggle} />}
            {type === 'member' && <Member accountToggle={accountToggle} />}
            {type === 'reset' && <Reset />}
            {type === 'register' && <Register />}
        </>
    );
}

Account.defaultProps = {
    accountToggle: null
};

Account.propTypes = {
    accountToggle: PropTypes.func
};

export default Account;
